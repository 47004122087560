export default defineI18nConfig(() => ({
  messages: {
    'en-us': {
      aria_label_home_link: 'Home',
      manage_cookie_prefs: 'Manage Cookie Preferences',
      all_resources: 'All Resources',
      all_events: 'All Events',
      event_time: 'Date of event: {time}',
      all_articles: 'All Articles',
      all_news: 'All News',
      table_of_contents: 'Table of Contents',
      loading_form_text: 'Form is loading...',
      error_btn: 'Go Home',
      '404_text': 'Sorry, but the page you were looking for could not be found.',
      '410_text': 'This page has been permanently deleted.',
      '500_text': 'Internal Server Error',
      read_more: 'Read more',
      back: 'Back',
      see_the_listing: 'See the listing',
      article: {
        written_by: 'Written by',
        reviewed_by: 'Reviewed by',
        min_to_read: 'min to read',
        products_used: 'Products used',
      },
      lang: {
        'fr-fr': 'Français',
        'de-de': 'Deutsch',
        'en-us': 'English',
      },
    },
    'de-de': {
      aria_label_home_link: 'Startseite',
      manage_cookie_prefs: 'Cookie-Einstellungen verwalten',
      all_resources: 'Alle Ressourcen',
      all_events: 'Alle Events',
      event_time: 'Zeit der Veranstaltung: {time}',
      all_articles: 'Alle Artikel',
      all_news: 'Alle Neuigkeiten',
      table_of_contents: 'Inhaltsübersicht',
      loading_form_text: 'Formular wird geladen...',
      error_btn: 'Zur Startseite',
      '404_text': 'Die von Ihnen gesuchte Seite konnte leider nicht gefunden werden.',
      '410_text': 'Diese Seite wurde endgültig gelöscht.',
      '500_text': 'Internal Server Error',
      read_more: 'Mehr lesen',
      back: 'Zurück',
      see_the_listing: 'Ansehen',
      article: {
        written_by: 'Geschrieben von',
        reviewed_by: 'Geprüft von',
        min_to_read: 'min Lesezeit',
        products_used: 'Genutzte Produkte',
      },
      lang: {
        'fr-fr': 'Français',
        'de-de': 'Deutsch',
        'en-us': 'English',
      },
    },
    'fr-fr': {
      aria_label_home_link: 'Accueil',
      manage_cookie_prefs: 'Gérer les préférences de cookies',
      all_resources: 'Toutes les ressources',
      all_events: 'Tous les événements',
      event_time: "Heure de l'événement: {time}",
      all_articles: 'Tous les articles',
      all_news: 'Toutes les nouvelles',
      table_of_contents: 'Table des matières',
      loading_form_text: 'Le formulaire se charge...',
      error_btn: "Vers la page d'accueil",
      '404_text': "Désolé, mais la page que vous recherchez n'a pas pu être trouvée.",
      '410_text': 'Cette page a été supprimée de façon permanente.',
      '500_text': 'Internal Server Error',
      read_more: 'En savoir plus',
      back: 'Retour',
      see_the_listing: 'Voir la liste',
      article: {
        written_by: 'Rédigé par',
        reviewed_by: 'Examiné par',
        min_to_read: 'min à lire',
        products_used: 'Produits utilisés',
      },
      lang: {
        'fr-fr': 'Français',
        'de-de': 'Deutsch',
        'en-us': 'English',
      },
    },
  },
}))
